import { inject } from "mobx-react";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import string from "../../../../../../utils/string";
import Procedimentos from "./components/Procedimentos";
import { getProfissionalSaudeLogado } from "../../../../../../services/UsuarioService";
import {
  defaultErrors,
  orcamentoDefault,
} from "../../../../../../stores/Orcamento.store";
import {
  findConfiguracaoUnidade,
  saveOrcamento,
} from "../../../../../../services/OdontogramaService";
import ModalOrcamento from "../../../../../Atendimento/Tabs/Odontograma/Modal/Orcamento/ModalOrcamento";
import moment from "moment";
import ImpressaoHtml from "../../../../../../components/Impressao/ImpressaoHtml";
import OrcamentoPdf from "../../../../../../template/pdf/orcamentos/OrcamentoPdf";
import { verificaIsDifferent } from "../../../../../../utils/verificaOrcamentosDiferentes";

const ModalOrcamentoProcedimento = observer(
  ({
    orcamentoStore,
    setOpenModalOrcamento,
    openModalOrcamento,
    sujeitoAtencaoSelected,
    loadList,
    odontogramaStore,
    configuracaoImpressaoStore,
    orcamentoComparar,
  }) => {
    const { dadosConvenio } = sujeitoAtencaoSelected || {};
    const { convenio: convenioSujeitoAtencao } = dadosConvenio || {};
    const { orcamento, handleFieldModal } = orcamentoStore;

    const [configUnidade, setConfigUnidade] = useState(null);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [openImpressaoOrcamento,setOpenImpressaoOrcamento] =useState(false)

    const {
      nome,
      convenio,
      dataVencimento,
      desconto,
      id,
      observacao,
      orcamentoProcedimentos,
      profissionalSaude,
      status,
      tipoPagamento,
      totalParcelas,
      valor,
      valorEntrada,
    } = orcamento || {};

    useEffect(() => {
      !id && getDadosOrcamento();
      getConfigUnidade();
      orcamentoStore.errors = defaultErrors;
    }, []);

    const getDadosOrcamento = async () => {
      getDadosSemOrcamento();
    };

    const getConfigUnidade = async () => {
      const configUnidade = await findConfiguracaoUnidade();

      setConfigUnidade(configUnidade);
    };

    const getDadosSemOrcamento = async () => {
      const { id, descricao } = convenioSujeitoAtencao || {};

      const profissionalLogado = await getProfissionalSaudeLogado();

      const profissionalSaude = {
        id: profissionalLogado.id,
        value: profissionalLogado.id,
        label: profissionalLogado.nome,
      };

      const convenio = convenioSujeitoAtencao
        ? {
            value: id,
            label: descricao,
          }
        : null;

      orcamentoStore.orcamento = {
        ...orcamento,
        profissionalSaude,
        convenio,
      };
    };

    const contemErros = () => {
      const procedimentosValido = orcamentoProcedimentos.length > 0;
      const errorDesconto = orcamentoStore.errorLimiteDesconto(
        configUnidade.limiteDescontoOrcamento
      );
      const errors = {
        nome: !nome,
        procedimentos: !procedimentosValido,
        tipoPagamento: !tipoPagamento,
        valor: !valor,
        desconto: errorDesconto,
      };

      orcamentoStore.errors = errors;
      const possuiErros = Object.values(errors).some((item) => item);

      return possuiErros;
    };

    const handleSave = async (action = "") => {
      try {
        if (contemErros()) {
          const message = orcamentoStore.errors.desconto
            ? "Limite de desconto ultrapassado."
            : "Favor verificar os campos obrigatórios";

            return orcamentoStore.openNotification(message, "error");
         
        }

        setIsLoadingSave(true);
        const procedimentos = orcamentoProcedimentos.map((item) => {
          return {
            procedimento: {
              id: item.procedimento.id,
            },
            valorUnitario:
              typeof item.valorUnitario === "string"
                ? string.currencyMaskToFloat(item.valorUnitario)
                : item.valorUnitario,
          };
        });

        const descontoNumber = string.removeMask(
          String(desconto.valorDesconto),
          "%"
        );

        const variables = {
          ...(id && { id }),
          nome,
          observacao,
          status: action || status || "PENDENTE",
          orcamentoProcedimentos: procedimentos,
          valor:
            typeof valor === "string"
              ? string.currencyMaskToFloat(valor)
              : valor,
          tipo: "PROCEDIMENTO",
          profissionalSaude: {
            id: profissionalSaude?.id,
          },
          ...(totalParcelas && { totalParcelas: totalParcelas.value }),
          ...(valorEntrada && {
            valorEntrada:
              typeof valorEntrada === "string"
                ? string.currencyMaskToFloat(valorEntrada)
                : valorEntrada,
          }),
          ...(convenio?.value && {
            convenio: { id: convenio?.value },
          }),
          ...(dataVencimento && {
            dataVencimento: moment(dataVencimento).format("YYYY-MM-DD"),
          }),
          ...(descontoNumber && {
            desconto: {
              valorDesconto: descontoNumber,
              tipoDesconto: desconto?.tipoDesconto?.value,
            },
          }),
          ...(tipoPagamento?.value && {
            tipoPagamento: tipoPagamento.value,
          }),
          sujeitoAtencao: {
            id: sujeitoAtencaoSelected.id,
          },
        };

        const response = await saveOrcamento(variables);
        await loadList();

        if (response.status === "PENDENTE") {
          orcamentoStore.orcamento = orcamentoDefault;
        } else if (response.status === "APROVADO") {
          orcamentoStore.orcamento = {
            ...orcamentoStore.orcamento,
            id: response?.id,
          };
          odontogramaStore.dadosModalConfirmacao.open = true;
        }

        orcamentoStore.openNotification(
          "Orçamento salvo com sucesso!",
          "success"
        );

        return response?.id
      } catch (e) {
        orcamentoStore.openNotification("Erro ao salvar o orçamento", "error");
        throw new Error(e)
      } finally {
        setIsLoadingSave(false);
      }
    };



    const handlePrint = async (e, action) => {
      try {
        await configuracaoImpressaoStore.getConfig("ORCAMENTO");
        if (!id) {
          const idNovoOrcamento = await handleSave(action);
          await orcamentoStore.findOrcamentoById(idNovoOrcamento);
        }
        else {
          const orcamentoSelecionado = { ...orcamento }
          const orcamentoSemAlteracoes = orcamentoComparar

          if (verificaIsDifferent(orcamentoSelecionado, orcamentoSemAlteracoes)) {
            const idOrcamentoUpdate = await handleSave()
            await orcamentoStore.findOrcamentoById(idOrcamentoUpdate);
          }
        }
        
        setOpenImpressaoOrcamento(true);
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <>
      <ModalOrcamento
        open={openModalOrcamento}
        onClose={() => setOpenModalOrcamento(false)}
        title={id ? "Editar orçamento" : "Emitir orçamento"}
        handleSave={handleSave}
        orcamento={orcamento}
        handleFieldModal={handleFieldModal}
        setIsLoadingSave={setIsLoadingSave}
        isLoadingSave={isLoadingSave}
        handlePrint={(e)=>handlePrint(e)}
      >
        <Procedimentos
          orcamentoProcedimentos={orcamentoProcedimentos}
          handleFieldModal={handleFieldModal}
          orcamento={orcamento}
        />
      </ModalOrcamento>
        {openImpressaoOrcamento &&
          <ImpressaoHtml
            habilitaBotaoImpressao={setOpenModalOrcamento}
            handlePrintMustache={() => setOpenImpressaoOrcamento(false)}
            isPrintMustache={openImpressaoOrcamento}
            htmlStringComponent={
              <OrcamentoPdf
                orcamento={orcamento}
              />}
          />}
      </>
    );
  }
);

export default inject(
  "orcamentoStore",
  "odontogramaStore",
  "configuracaoImpressaoStore",
)(ModalOrcamentoProcedimento);
